.rotate {
    /* white-space: nowrap;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg); */
    /* writing-mode: lr-tb;
    padding: 0 !important;
    margin: 0 !important; */
    /* width: 5000px !important; */
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 5px;
}

td {
    height: 3.2rem;
}

.indices_table {
    width: 30rem;
}


@media (max-width: 300px) {
    .tablecomponent {
        font-size: 0.3rem;
        line-height: 0.6rem;
        letter-spacing: 0rem;
    }
    .indices_table{
        width: 8rem;
    }
    td {
        height: 1.5rem;
        max-width: 30px;
    }
}

@media ( min-width: 301px) and (max-width: 350px) {
    .tablecomponent {
        font-size: 0.5rem;
        line-height: 0.7rem;
        letter-spacing: 0rem;
    }
    .indices_table{
        width: 15rem;
    }
    td {
        height: 2rem;
        max-width: 35px;
    }
}

@media ( min-width: 351px) and (max-width: 400px) {
    .tablecomponent {
        font-size: 0.55rem;
        line-height: 0.8rem;
        letter-spacing: 0rem;
    }
    .indices_table{
        width: 16rem;
    }
    td {
        height: 2.2rem;
        max-width: 50px;
    }
}

@media ( min-width: 401px) and (max-width: 640px) {
    .tablecomponent {
        font-size: 0.56rem;
        line-height: 0.9rem;
        letter-spacing: 0rem;
    }
    .indices_table{
        width: 19rem;
    }
    td {
        height: 2.2rem;
    }
}

@media ( min-width: 1400px) {
    .td-category{
        min-width: 11rem;
    }
}
@media ( min-width: 1536px) {
    td {
        height: auto;
    }
}

.rotate p {
    -moz-transform: rotate(270deg);
    /* FF3.5+ */
    -o-transform: rotate(270deg);
    /* Opera 10.5 */
    -webkit-transform: rotate(270deg);
    /* Saf3.1+, Chrome */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
    /* IE6,IE7 */
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
    /* IE8 */
    margin-left: -10em;
    margin-right: -10em;
}

.cell_bg_gray {
    background-color: rgba(0, 0, 0, 0.6);
    color: rgba(255, 255, 255, 0.85);
}

.cell_bg_green {
    background-color: #c7e1b4;
    color: rgba(0, 0, 0, 1);
}

.cell_bg_yellow {
    background-color: #fff3cd;
    color: rgba(0, 0, 0, 1);
}

.cell_bg_red {
    background-color: #ff5353;
    color: rgba(0, 0, 0, 1);
}

div > p {
    text-align: justify;
}
