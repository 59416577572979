@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
html {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.03rem;
  padding: 0;
  margin: 0;
}

.bg_green {
  color: #48d138;
}

.bg_yellow {
  color: #f0c311;
}

.bg_red {
  color: #ff5353;
}
